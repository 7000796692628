import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  token: string;
  campaigns: any[] = [];
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.getCapmpgain();

  }

getCapmpgain(){
  this.spinner.show();
  this.apiService.getCampaign(this.token).subscribe(
    (res) => {
      this.spinner.hide();
      console.log(res);
      if (res['status'] == 'success') {
        this.campaigns = res['result'];
        this.campaigns.forEach((campaign) => {
          let date = campaign.updated_at;
          campaign.updated_at = moment(campaign.updated_at).format(
            'Do MMMM YYYY'
          );
        });
        console.log(this.campaigns);
      }
    },
    (err) => {
      this.spinner.hide();
      console.log(err);
    }
  );
}

  handleGoBtn(id) {
    let data = {
      data: id
    }
    this.router.navigate(['/campaign-home', data]);
  }
}
