<div class="container px-30 mb-5">
  <div class="text-center pt-4 mb-2" routerLink="/dashboard">
    <img
      src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270701620.png"
      class="text-center"
    />
  </div>
  <div class="back-btn pl-0 mb-3">
    <span [routerLink]="['/dashboard']">
      <!-- <img src="./assets/images/leftArrow.svg" /> -->

      <img
        src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655354910895.png"
      />
    </span>
  </div>
  <div>
    <div class="row mb-3">
      <div class="col-6 col-md-6 type-container">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="priority">Priority</div>
          </button>
          <div class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton">
            <!-- <div>
              <label for="1">1</label>
              <input
                type="checkbox"
                id="1"
                class="inputPriorityClass"
                [value]="1"
                (change)="changeSelection($event)"
              />

              <label for="10A">10A</label>
              <input
                type="checkbox"
                id="10A"
                class="inputPriorityClass"
                [value]="10A"
                (change)="changeSelection($event)"
              />

              <label for="10B">10B</label>
              <input
                type="checkbox"
                id="10B"
                class="inputPriorityClass"
                [value]="10B"
                (change)="changeSelection($event)"
              />

              <label for="10C">10C</label>
              <input
                type="checkbox"
                id="10C"
                class="inputPriorityClass"
                [value]="10C"
                (change)="changeSelection($event)"
              />
              <label for="11A">11A</label>
              <input
                type="checkbox"
                id="11A"
                class="inputPriorityClass"
                [value]="11A"
                (change)="changeSelection($event)"
              />

              <label for="11B">11B</label>
              <input
                type="checkbox"
                id="11B"
                class="inputPriorityClass"
                [value]="11B"
                (change)="changeSelection($event)"
              />

              <label for="11C">11C</label>
              <input
                type="checkbox"
                id="11C"
                class="inputPriorityClass"
                [value]="11C"
                (change)="changeSelection($event)"
              />

              <label for="12">12</label>
              <input
                type="checkbox"
                id="12"
                class="inputPriorityClass"
                [value]="12"
                (change)="changeSelection($event)"
              />

              <label for="12A">12A</label>
              <input
                type="checkbox"
                id="12A"
                class="inputPriorityClass"
                [value]="12A"
                (change)="changeSelection($event)"
              />

              <label for="12B">12B</label>
              <input
                type="checkbox"
                id="12B"
                class="inputPriorityClass"
                [value]="12B"
                (change)="changeSelection($event)"
              />

              <label for="12C">12C</label>
              <input
                type="checkbox"
                id="12C"
                class="inputPriorityClass"
                [value]="12C"
                (change)="changeSelection($event)"
              />

              <label for="13A">13A</label>
              <input
                type="checkbox"
                id="13A"
                class="inputPriorityClass"
                [value]="13A"
                (change)="changeSelection($event)"
              />

              <label for="13B">13B</label>
              <input
                type="checkbox"
                id="13B"
                class="inputPriorityClass"
                [value]="13B"
                (change)="changeSelection($event)"
              />

              <label for="13C">13C</label>
              <input
                type="checkbox"
                id="13C"
                class="inputPriorityClass"
                [value]="13C"
                (change)="changeSelection($event)"
              />

              <label for="14A">14A</label>
              <input
                type="checkbox"
                id="14A"
                class="inputPriorityClass"
                [value]="14A"
                (change)="changeSelection($event)"
              />

              <label for="14B">14B</label>
              <input
                type="checkbox"
                id="14B"
                class="inputPriorityClass"
                [value]="14B"
                (change)="changeSelection($event)"
              />

              <label for="14C">14C</label>
              <input
                type="checkbox"
                id="14C"
                class="inputPriorityClass"
                [value]="14C"
                (change)="changeSelection($event)"
              />

              <label for="5">5</label>
              <input
                type="checkbox"
                id="5"
                class="inputPriorityClass"
                [value]="5"
                (change)="changeSelection($event)"
              />

              <label for="6">6</label>
              <input
                type="checkbox"
                id="6"
                class="inputPriorityClass"
                [value]="6"
                (change)="changeSelection($event)"
              />

              <label for="7">7</label>
              <input
                type="checkbox"
                id="7"
                class="inputPriorityClass"
                [value]="7"
                (change)="changeSelection($event)"
              />

              <label for="8">8</label>
              <input
                type="checkbox"
                id="8"
                class="inputPriorityClass"
                [value]="8"
                (change)="changeSelection($event)"
              />

              <label for="9">9</label>
              <input
                type="checkbox"
                id="9"
                class="inputPriorityClass"
                [value]="9"
                (change)="changeSelection($event)"
              />
            </div> -->
            <div *ngFor="let priority of priorityHardcodedArray" class="">
              <label for="priorityall">{{ priority }}</label>
              <input
                type="checkbox"
                id="priorityall"
                style="float: right; height: 20px"
                [value]="priority"
                (change)="changeSelection($event)"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <p class="font-green breakall">{{ uniquePriorityString }}</p>
        </div>
      </div>
      <div class="col-6 col-md-6 type-container">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="typeString">Type</div>
          </button>
          <div class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton">
            <!-- <div>
              <label for="dnc">DNC</label>
              <input
                type="checkbox"
                id="dnc"
                value="dnc"
                style="float: right; height: 20px"
                (change)="changeTypeSelection($event)"
              />
            </div>
            <div>
              <label for="incorrect">Incorrect</label>
              <input
                type="checkbox"
                id="incorrect"
                style="float: right; height: 20px"
                value="incorrect"
                (change)="changeTypeSelection($event)"
                class="checkTypeAll"
              />
            </div> -->
            <div>
              <label for="completed">Not Called</label>
              <input
                type="radio"
                name="type"
                id="completed"
                style="float: right; height: 20px"
                value="not_called_flag"
                (change)="changeTypeSelection($event)"
                class="checkTypeAll"
              />
            </div>
            <div>
              <label for="call_later">Call Back</label>
              <input
                type="radio"
                name="type"
                id="call_later"
                style="float: right; height: 20px"
                value="call_later"
                (change)="changeTypeSelection($event)"
                class="checkTypeAll"
              />
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <p class="font-green breakall">{{ uniqueTypeString }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container light-blue-bg pb-5">
    <div *ngIf="customerData != undefined">
      <h4 class="pt-4 pb-2">Customer Detail</h4>
      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14"> Full Name </strong>
        </div>
        <div class="col-md-6 col-6">
          <p>{{ customerData?.first_name }} {{ customerData?.last_name }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14"> Phone Number </strong>
        </div>
        <div class="col-md-6 col-6">
          <p>
            <a href="tel:{{ customerData?.mobile }}">{{
              customerData?.mobile
            }}</a>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14"> Gender</strong>
        </div>
        <div class="col-md-6 col-6">
          <p>{{ customerData?.gender }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14"> Date of Birth</strong>
        </div>
        <div class="col-md-6 col-6">
          <p>{{ customerData?.dob }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14">Tier</strong>
        </div>
        <div class="col-md-6 col-6">
          <p>{{ customerData?.tier }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <strong class="fs-14">Priority</strong>
        </div>
        <div class="col-md-6 col-6">
          <p>{{ customerData?.priority }}</p>
        </div>
      </div>
      <form [formGroup]="campaignForm" (ngSubmit)="onSubmitCampaignForm()">
        <div>
          <div class="row">
            <div class="col-6 col-md-6">
              <strong class="fs-14">Attending ?</strong>
            </div>
            <div class="col-6 col-md-6">
              <div class="row">
                <div class="col-6 col-md-6 pr-0">
                  <input
                    type="radio"
                    value="true"
                    name="attending"
                    class="radioYes"
                    (change)="attending($event)"
                  />
                  <label class="pl-2">Yes</label>
                </div>
                <div class="col-6 col-md-6 pr-0">
                  <input
                    type="radio"
                    value="false"
                    name="attending"
                    class="radioNo"
                    (change)="attending($event)"
                  />
                  <label class="pl-2">No</label>
                </div>
              </div>
              <div *ngIf="radioErrorMsg" class="error fs-14">
                *{{ radioErrorMsg }}
              </div>
            </div>
          </div>
        </div>
        <div class="buttons fs-15">
          <div class="row d-flex justify-content-between mb-3">
            <div class="col-6 col-md-6">
              <button
                type="button"
                (click)="updateCustomer('completed_flag')"
                class="p-3"
              >
                Completed
              </button>
            </div>
            <div class="col-6 col-md-6">
              <button
                type="button"
                class="p-3"
                (click)="updateCustomer('call_later_flag')"
              >
                Call Back
              </button>
            </div>
          </div>
          <div class="row d-flex justify-content-between bottom-buttons">
            <div class="col-6 col-md-6">
              <button
                type="button"
                class="p-1"
                (click)="updateCustomer('incorrect_flag')"
              >
                Incorrect Number
              </button>
            </div>
            <div class="col-6 col-md-6">
              <button
                type="button"
                class="p-1"
                (click)="updateCustomer('do_not_call_flag')"
              >
                Do not Call
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- <div class="customer-count">
        <div class="mb-2">
          <strong class="fs-16">Customer Counts</strong>
        </div>
        <div class="row m-0 mb-2">
          <div class="col-6 col-md-6">
            <div class="count-container">
              <p>Total Calls</p>
              <strong>{{ toatalCount }}</strong>
            </div>
          </div>
          <div class="col-6 col-md-6">
            <div class="count-container">
              <p>Calls Completed</p>
              <strong>{{ completedCount }}</strong>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-4 col-md-4">
            <div class="count-container">
              <p>Call Back</p>
              <strong>{{ callBackuCount }}</strong>
            </div>
          </div>
          <div class="col-4 col-md-4">
            <div class="count-container">
              <p>Do Not Call</p>
              <strong>{{ dncCount }}</strong>
            </div>
          </div>
          <div class="col-4 col-md-4">
            <div class="count-container">
              <p>Incorrect No.</p>
              <strong>{{ incorrectCount }}</strong>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div *ngIf="customerData == undefined">
      <h4 class="pt-4 pb-2">No details are available</h4>
    </div>
    <div class="buttons">
      <button (click)="logout()" type="button" class="p-1">Logout</button>
    </div>
  </div>
</div>

<!-- <div>
      <form [formGroup]="priorityForm">
        <div class="">
          <ul class="ks-cboxtags">
            <li>
              <input
                type="checkbox"
                id="checkboxOne"
                value="Rainbow Dash"
                disabled
              /><label for="checkboxOne">All</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="checkboxOne"
                value="Rainbow Dash"
              /><label for="checkboxOne">1</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="checkboxTwo"
                value="Cotton Candy"
                checked
              /><label for="checkboxTwo">2</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="checkboxThree"
                value="Rarity"
                checked
              /><label for="checkboxThree">3</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="checkboxFour"
                value="Moondancer"
              /><label for="checkboxFour">4</label>
            </li>
            <li>
              <input type="checkbox" id="checkboxFive" value="Surprise" /><label
                for="checkboxFive"
                >5</label
              >
            </li>
          </ul>
        </div>
      </form>
    </div> -->
<div
  bsModal
  #errorPopup="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <p class="text-center">This account is being used in another device.</p>
        <p class="text-center">Please login again.</p>

        <div class="text-center buttons p-0">
          <button type="submit" class="blueBtn" (click)="onClickerrorModalOK()">
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
