<div class="container px-30 login-container">
  <div class="text-center pt-4">
    <img src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270701620.png" class="text-center" />
  </div>
  <div class="pt-5">
    <h4 class="text-left">OTP</h4>
    <p class="text-muted fs-15">
      Please enter your mobile number to validate and sign in
    </p>
  </div>
  <div class="login-form text-center">
    <form
      [formGroup]="otpForm"
      (ngSubmit)="onSubmitOtpForm()"
      class="form-container text-center"
    >
      <div class="input-block">
        <span>
          <!-- <img src="../../assets/images/cellphone-key.png" /> -->
        </span>
        <div>
          <input
            placeholder="Enter otp here"
            formControlName="otp"
            maxlength="4" (keypress)="onlyNumbers($event)"
            [ngClass]="{
              'is-invalid': submittedOtpForm && otpFormVal.otp.errors
            }"
          />
        </div>
      </div>

      <div *ngIf="submittedOtpForm && otpFormVal.otp.errors" class="error">
        <span *ngIf="otpFormVal.otp.errors.required"
          >Please enter OTP</span
        >
      </div>
      <div *ngIf="errorMsg" class="error">
        <span>{{ errorMsg }}</span>
      </div>
      <div>
        <button type="submit" class="get-otp-btn fs-15">Validate OTP</button>
      </div>
    </form>
  </div>
  <div class="footer">
    <p class="copyright fs-14">Copyright © 2022. All rights reserved</p>
  </div>
</div>
