import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  submittedOtpForm: boolean = false;
  errorMsg: string;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    localStorage.setItem('mobile', this.route.snapshot?.params?.mobileNumber);
    console.log(this.route.snapshot.params.mobileNumber);
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });
  }
  get otpFormVal() {
    return this.otpForm.controls;
  }

  onlyNumbers(ev) {
    this.errorMsg = '';
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onSubmitOtpForm() {
    this.submittedOtpForm = true;
    this.spinner.show();
    console.log(this.otpFormVal);
    if (this.otpForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      let formdata = {
        mobile: this.route.snapshot?.params?.mobileNumber,
        value: this.otpForm.controls['otp'].value,
      };
      this.apiService.otpValidate(formdata).subscribe(
        (res) => {
          this.spinner.hide();
          console.log(res['status']);
          if (res['status'] === 'success') {
            this.errorMsg = '';
            console.log(res['result']);
            this.router.navigate(['/dashboard']);
            localStorage.setItem('token', res['result'].token);
          }
        },
        (err) => {
          this.spinner.hide();
          console.log(err['status']);
          if (err['status'] == 400) {
            this.spinner.hide();
            this.errorMsg = 'Invalid OTP';
            // alert(this.errorMsg);
          } else if (err['status'] == 504) {
            this.spinner.hide();
            this.errorMsg = 'Took too long to respond';
            // alert(this.errorMsg);
          }
        }
      );
    }
  }
}
