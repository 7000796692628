import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private toaster: ToastrService) {}

  showToaster(msg: any) {
    this.toaster.info(msg);
  }

  // https://pantaloons-api-dev.sttarter.com/api/v1/otp/generate?mobile=7975189947
  otpGenerate(mobileNumber: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get(
      env.AUTH_BASE_URL + `/otp/generate?mobile=${mobileNumber}`,
      { headers: headers }
    );
  }

  otpValidate(formData: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(env.AUTH_BASE_URL + '/otp/validate', formData, {
      headers: headers,
    });
  }

  getCampaign(token) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-user-token': token,
    });
    return this.http.get(env.AUTH_BASE_URL + '/campaign', {
      headers: headers,
    });
  }

  getPriorities(token) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-user-token': token,
    });
    return this.http.get(env.AUTH_BASE_URL + '/priority', {
      headers: headers,
    });
  }

  getCustomer(token, mobile, campaignid, priority, type) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-user-token': token,
    });
    return this.http.get(
      env.AUTH_BASE_URL +
        `/customer?mobile=${mobile}&campaign_id=${campaignid}&priority=${priority}&type=${type}`,
      {
        headers: headers,
      }
    );
  }

  updateCustomer(token, id, formdata) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-user-token': token,
    });
    return this.http.put(env.AUTH_BASE_URL + `/customer/${id}`, formdata, {
      headers: headers,
    });
  }
}
