<div class="container px-30 bottom-boder-yellow dashboard-container">
  <div class="text-center pt-4">
    <img
      src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270701620.png"
      class="text-center"
    />
  </div>
  <div class="pt-5">
    <h4 class="text-left">Campaign</h4>
    <p class="text-muted fs-15">
      List of all active campaigns
      
    </p>
  </div>

  <!-- <section class="select">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group mb-4">
            <strong>Select Campaign:</strong>
            <select class="form-control">
              <option value="EOSS Campaign" selected>EOSS Campaign</option>
              <option value="Pujo Campaign">Pujo Campaign</option>
              <option value="Diwali Campaign">Diwali Campaign</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-4">
            <strong>Call Rule Selection</strong>
            <select class="form-control">
              <option value="Not Call" selected>Not Call</option>
              <option value="Call Back">Call Back</option>
            </select>
          </div>
        </div>
      </div>

  </section>  -->

  <div class="row" *ngIf="campaigns.length > 0">
    <div class="col-md-6 col-12 mb-3" *ngFor="let campaign of campaigns">
      <div class="card">
        <div class="m-2 card-image">
          <img
            class="card-img-top"
            src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270733931.png"
            alt="Card image cap"
          />
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="pb-3">
              <strong class="fs-15 fw500">{{ campaign.campaign_name }}</strong>
              <p class="card-text text-muted fs-14">
                {{campaign?.campaign_message}}
              </p>
            </div>
            <div>
              <button class="go-btn" (click)="handleGoBtn(campaign.id)">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270688943.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-6 col-12 mb-3">
      <div class="card">
        <div class="m-2 card-image">
          <img
            class="card-img-top"
            src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270733931.png"
            alt="Card image cap"
          />
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="pb-3">
              <strong class="fs-15 fw500">Pantaloons EOSS</strong>
              <p class="card-text text-muted fs-14">31st July 2022</p>
            </div>
            <div>
              <button class="go-btn" (click)="handleGoBtn()">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270688943.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12 mb-3">
      <div class="card">
        <div class="m-2 card-image">
          <img
            class="card-img-top"
            src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270733931.png"
            alt="Card image cap"
          />
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="pb-3">
              <strong class="fs-15 fw500">Pantaloons EOSS</strong>
              <p class="card-text text-muted fs-14">31st July 2022</p>
            </div>
            <div>
              <button class="go-btn" (click)="handleGoBtn()">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270688943.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12 mb-3">
      <div class="card">
        <div class="m-2 card-image">
          <img
            class="card-img-top"
            src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270733931.png"
            alt="Card image cap"
          />
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="pb-3">
              <strong class="fs-15 fw500">Pantaloons EOSS</strong>
              <p class="card-text text-muted fs-14">31st July 2022</p>
            </div>
            <div>
              <button class="go-btn" (click)="handleGoBtn()">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270688943.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <div class="callDetail">
    <div class="row mb-2">
      <div class="col-8">
        <strong>Total Calls Assign : </strong>
      </div>
      <div class="col-4">
        <span>40,000</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8">
        <strong>Not Calls : </strong>
      </div>
      <div class="col-4">
        <span>38,000</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8">
        <strong>Call Back : </strong>
      </div>
      <div class="col-4">
        <span>10</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8">
        <strong>Incorrect Numbers : </strong>
      </div>
      <div class="col-4">
        <span>3</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8">
        <strong>Call Completed : </strong>
      </div>
      <div class="col-4">
        <span>1987</span>
      </div>
    </div>
  </div> -->
</div>
