<div class="container px-30 login-container">
  <div class="text-center pt-4">
    <img src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270701620.png" class="text-center" />
  </div>
  <div class="pt-5">
    <h4 class="text-left">LOGIN</h4>
    <p class="text-muted fs-15">
      Please enter your mobile number to register or sign in
    </p>
  </div>
  <div class="login-form text-center">
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmitLoginForm()"
      class="form-container text-center"
    >
      <div
        class="input-block"
        [ngClass]="{
          'is-invalid': submittedLoginForm && loginFormVal.mobileNumber.errors
        }"
      >
        <span>
          <img src="https://s3-ap-southeast-1.amazonaws.com/sttarter-live-legacy/62b8b17263017ed475b3bfe42ec6a2b2-1655270806168.png" />
        </span>
        <div>
          <input placeholder="Mobile number" formControlName="mobileNumber" maxlength="10" (keypress)="onlyNumbers($event)"/>
        </div>
      </div>

      <div
        *ngIf="submittedLoginForm && loginFormVal.mobileNumber.errors"
        class="error"
      >
        <span *ngIf="loginFormVal.mobileNumber.errors.required"
          >Please enter mobile number</span
        >
      </div>
      <div *ngIf="errorMsg" class="error">
        {{ errorMsg }}
      </div>
      <div>
        <button type="submit" class="get-otp-btn fs-15">GET OTP</button>
      </div>
    </form>
  </div>
  <div class="footer">
    <p class="copyright fs-14">Copyright © 2022. All rights reserved</p>
  </div>
</div>
