import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submittedLoginForm: boolean = false;
  errorMsg: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required]],
    });
    console.log(this.loginFormVal, this.loginForm);
  }
  get loginFormVal() {
    return this.loginForm.controls;
  }

  onlyNumbers(ev) {
    this.errorMsg = '';
    let onlynum = /^[0-9]*$/;
    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onSubmitLoginForm() {
    this.submittedLoginForm = true;
    this.spinner.show();

    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    } else {
      let mobile = this.loginForm.controls['mobileNumber'].value;
      this.apiService.otpGenerate(mobile).subscribe(
        (res) => {
          this.spinner.hide();
          console.log(res['status']);
          if (res['status'] === 'success') {
            this.errorMsg = '';
            console.log(res['result']);
            this.router.navigate(['/otp', { mobileNumber: mobile }]);
          } else if (res['status'] == 400) {
          }
        },
        (err) => {
          this.spinner.hide();
          if (err['status'] == 400) {
            this.spinner.hide();
            this.errorMsg = err.error.message;
            // alert(this.errorMsg)
          }
          if(err){
            this.spinner.hide();
            this.errorMsg = err.error.message;
            // alert(this.errorMsg)
          }
        }
      );
    }
  }
}
