import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-campaign-home',
  templateUrl: './campaign-home.component.html',
  styleUrls: ['./campaign-home.component.scss'],
})
export class CampaignHomeComponent implements OnInit {
  campaignForm: FormGroup;
  priorityForm: FormGroup;
  priorityArr: any[] = [];
  typeArr: any[] = [];
  uniqueTypeArray: any[];
  uniqueTypeString: any = '';
  uniquePriorityArray: any[];
  uniquePriorityString: any = '';
  token: string;
  priorityList: any;
  mobile: string;
  customerData: any;
  incorrectCount: any;
  toatalCount: any;
  completedCount: any;
  dncCount: any;
  callBackuCount: any;
  campaignid: any;
  isAttending: any;
  customerID: any;
  formdata: any;
  filteredPriorityList: any[] = [];
  radioBtnSelect = null;
  radioErrorMsg = '';
  priorityHardcodedArray: any[] = [
    '1',
    '10A',
    '10B',
    '10C',
    '11A',
    '11B',
    '11C',
    '12',
    '12A',
    '12B',
    '12C',
    '13A',
    '13B',
    '13C',
    '14A',
    '14B',
    '14C',
    '5',
    '6',
    '7',
    '8',
    '9',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
  ];

  @ViewChild('errorPopup', { static: true }) errorPopup: ModalDirective;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService
  ) {
    this.campaignid = this.route.snapshot.params.data;
    console.log(this.campaignid, ' this.campaignid');
  }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.mobile = localStorage.getItem('mobile');
    this.campaignForm = this.formBuilder.group({
      isIntrestedInEOSS: [''],
      isNotIntrestedInEOSS: [''],
    });

    // this.getPriorities();
    this.getCustomer();
  }

  // getPriorities() {
  //   this.spinner.show();
  //   this.apiService.getPriorities(this.token).subscribe(
  //     (res) => {
  //       this.spinner.hide();
  //       // console.log(res);
  //       this.priorityList = res['result'];
  //       this.filteredPriorityList = this.priorityList.filter(
  //         (v, i, a) =>
  //           a.findIndex(
  //             (t) => t.priority === v.priority && t.priority != null
  //           ) === i
  //       );
  //       console.log(this.filteredPriorityList);
  //       this.priorityList = this.filteredPriorityList;
  //     },
  //     (err) => {
  //       this.spinner.hide();
  //       console.log(err);
  //     }
  //   );
  // }

  getCustomer() {
    this.spinner.show();
    this.apiService
      .getCustomer(
        this.token,
        this.mobile,
        this.campaignid,
        this.uniquePriorityString,
        this.uniqueTypeString
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.customerID = res['result']?.customer_data?.id;
          this.customerData = res['result']?.customer_data;
          console.log(this.customerData, 'this.customerData');
          // if (res['result'] != {}) {
          //   this.incorrectCount =
          //     res['result']['incorrect_count'][0]?.incorrect_count;
          //   this.toatalCount = res['result']['total_count'][0]?.total_count;
          //   this.completedCount =
          //     res['result']['completed_count'][0]?.completed_count;
          //   this.callBackuCount =
          //     res['result']['call_back_count'][0]?.call_back_count;
          //   this.dncCount = res['result']['dnc_count'][0]?.dnc_count;
          //   console.log(res);
          // }
        },
        (err) => {
          this.spinner.hide();
          console.log(err);
        }
      );
  }

  onSubmitCampaignForm() {}

  changeSelection(event) {
    if (event.target.checked == true) {
      this.priorityArr.push(event.target.value);
    } else {
      this.priorityArr = this.priorityArr.filter((item) => {
        return item != event.target.value;
      });
    }
    this.uniquePriorityArray = [...new Set(this.priorityArr)];
    this.uniquePriorityString = '';
    this.uniquePriorityString = this.uniquePriorityArray.join(',');
    if (event.target.value == 'all') {
      if (event.target.checked == true) {
        var inp = document.querySelectorAll('.checkall');
        this.uniquePriorityString = 'ALL';
        for (var i = 0; i < inp.length; i++) {
          inp[i]['checked'] = true;
        }
      } else {
        var inp = document.querySelectorAll('.checkall');
        this.priorityArr = [];
        this.uniquePriorityArray = [];
        this.uniquePriorityString = '';
        for (var i = 0; i < inp.length; i++) {
          inp[i]['checked'] = false;
        }
      }
    }
    this.getCustomer();

    // console.log(this.uniquePriorityArray);
    // console.log(this.uniquePriorityString);
  }
  changeTypeSelection(event) {
    this.uniqueTypeString = event.target.value;

    // if (event.target.checked == true) {
    //   this.typeArr.push(event.target.value);
    // } else {
    //   this.typeArr = this.typeArr.filter((item) => {
    //     return item != event.target.value;
    //   });
    // }
    // this.uniqueTypeArray = [...new Set(this.typeArr)];
    // this.uniqueTypeString = '';
    // this.uniqueTypeString = this.uniqueTypeArray.join(',');
    // if (event.target.value == 'TypeAll') {
    //   if (event.target.checked == true) {
    //     var inp = document.querySelectorAll('.checkTypeAll');
    //     this.uniqueTypeString = 'ALL';
    //     for (var i = 0; i < inp.length; i++) {
    //       inp[i]['checked'] = true;
    //     }
    //   } else {
    //     var inp = document.querySelectorAll('.checkall');
    //     this.typeArr = [];
    //     this.uniqueTypeArray = [];
    //     this.uniqueTypeString = '';
    //     for (var i = 0; i < inp.length; i++) {
    //       inp[i]['checked'] = false;
    //     }
    //   }
    // }

    // console.log(this.uniqueTypeArray);
    // console.log(this.uniqueTypeString);
    this.getCustomer();
  }
  onClickerrorModalOK() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.errorPopup.hide();
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  attending(event) {
    console.log(event.target.value, 'attending value');
    if (event.target.value == 'true') {
      this.isAttending = true;
    } else if (event.target.value == 'false') {
      this.isAttending = false;
    }
    console.log(document.querySelectorAll('.radioYes')[0]['checked']);
    console.log(document.querySelectorAll('.radioNo')[0]['checked']);
  }

  updateCustomer(data) {
    this.radioBtnSelect = null;
    console.log(data, 'data');
    if (data == 'completed_flag') {
      console.warn('hereeeeeeee');
      if (
        document.querySelectorAll('.radioNo')[0]['checked'] == false &&
        document.querySelectorAll('.radioYes')[0]['checked'] == false
      ) {
        this.spinner.hide();
        console.log('==================');
        this.radioErrorMsg = 'Please select yes or no.';
        return;
      }
    }
    this.spinner.show();

    if (data == 'completed_flag') {
      console.log('tthhhhhhhhhhhhhh');
      this.formdata = {
        completed_flag: true,
        is_attending: this.isAttending,
      };
    } else if (data == 'call_later_flag') {
      this.formdata = {
        call_later_flag: true,
        is_attending: this.isAttending,
      };
    } else if (data == 'incorrect_flag') {
      this.formdata = {
        incorrect_flag: true,
        is_attending: this.isAttending,
      };
    } else if (data == 'do_not_call_flag') {
      this.formdata = {
        do_not_call_flag: true,
        is_attending: this.isAttending,
      };
    }

    document.querySelectorAll('.radioYes')[0]['checked'] = false;
    document.querySelectorAll('.radioNo')[0]['checked'] = false;

    this.apiService
      .updateCustomer(this.token, this.customerID, this.formdata)
      .subscribe(
        (res) => {
          this.radioErrorMsg = '';
          this.spinner.hide();
          console.log(res, '=========update customer========');
          if (res['status'] == 'success') {
            this.toaster.success('Customer Updated Sucessfully');

            this.getCustomer();
          } else {
            this.toaster.error(
              'Customer details not updated! Please try again.'
            );
          }

          // this.customerID = res['result']?.customer_data?.id;
          // this.customerData = res['result']?.customer_data;
          // console.log(this.customerData, 'this.customerData');
          // this.incorrectCount =
          //   res['result']['incorrect_count'][0]?.incorrect_count;
          // this.toatalCount = res['result']['total_count'][0]?.total_count;
          // this.completedCount =
          //   res['result']['completed_count'][0]?.completed_count;
          // this.callBackuCount =
          //   res['result']['call_back_count'][0]?.call_back_count;
          // this.dncCount = res['result']['dnc_count'][0]?.dnc_count;
        },
        (err) => {
          console.log(err.error.message);
          this.radioErrorMsg = '';
          this.spinner.hide();
          console.log(err?.statusText);
          if (err?.error?.message == 'Invalid Token!') {
            this.errorPopup.show();
          } else {
            alert(err);
          }
        }
      );
  }
}
